import React, { forwardRef } from 'react'
import { ScrollContainer } from 'react-indiana-drag-scroll'
import { chakra } from '@chakra-ui/react'

const ScrollContainerChakra = chakra(ScrollContainer)

export const ScrollDragContainer = forwardRef<HTMLElement, any>((props, ref) => {
  return <ScrollContainerChakra cursor="unset" {...props} ref={ref as any} />
})

ScrollDragContainer.displayName = 'ScrollDragContainer'
