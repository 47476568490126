import { BaseBuildAttrs, BaseDocument } from '@/core/typings'
import { Document, PaginateModel } from 'mongoose'

export interface MediaDTO {
  id: string
  url: string
  type: MediaType
  isMain: boolean
  name: string
}

export enum MediaType {
  Image = 'image',
  PDF = 'pdf',
  Video = 'video'
}

export interface MediaIdentifier {
  mediaId: string
}

export enum ImageVariants {
  'thumb' = 'thumb',
  '1920x1080' = '1920x1080',
  '600x600' = '600x600'
}

export interface MediaAttrs {
  name: string
  url: string
  type: MediaType
  isMain?: boolean
}

export interface MediaDoc extends BaseDocument<Document> {
  url: string
  name: string
  type: MediaType
  isMain: boolean

  MediaDTO(): MediaDTO
}

export interface MediaModel extends PaginateModel<MediaDoc> {
  build(attrs: BaseBuildAttrs<MediaAttrs>): MediaDoc
}
