'use client'
import React, { FC, ReactNode, useCallback } from 'react'
import { Stack, Text, useDisclosure, VStack } from '@chakra-ui/react'
import CustomModal from '@/app/components/common/CustomModal'
import { BrokerDTO } from '@/core/broker/typings'
import { RealEstateDTO, RealEstateLightDTO } from '@/core/real-estate/typings'
import RealEstateAddress from '@/app/(public)/components/RealEstateAddress'
import BrokerContactForm from '@/app/components/forms/BrokerContactForm'
import { Link } from '@chakra-ui/next-js'
import BrokerContactCard from '@/app/components/common/BrokerContactCard'
import { ContactBrokerClose, ContactBrokerOpen, gtmEvent } from '@/utils/gtm'
import useTimer from '@/hooks/useTimer'

interface ContactBrokerModalProps {
  broker: BrokerDTO
  realEstate: RealEstateLightDTO | RealEstateDTO
  trigger: ReactNode
}

const ContactBrokerModal: FC<ContactBrokerModalProps> = ({ trigger, broker, realEstate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [getBrokerModalTime, resetTimer] = useTimer(isOpen)

  const handleOpen = useCallback(() => {
    resetTimer()
    gtmEvent<ContactBrokerOpen>('contact_broker_open', { broker_id: broker.id })
    onOpen()
  }, [resetTimer, broker.id, onOpen])

  const handleClose = useCallback(() => {
    const time = getBrokerModalTime()
    const timeSpent = Math.round(time / 1000)

    gtmEvent<ContactBrokerClose>('contact_broker_close', {
      broker_id: broker.id,
      duration_seconds: timeSpent
    })

    resetTimer()
    onClose()
  }, [getBrokerModalTime, broker.id, resetTimer, onClose])

  return (
    <CustomModal
      size={{ base: 'sm', md: '5xl' }}
      onTriggerClick={handleOpen}
      trigger={trigger}
      onClose={handleClose}
      {...{ isOpen, onOpen }}>
      <Stack flexDirection={{ base: 'column', md: 'row' }} spacing={12} p={4} py={12} w={'full'}>
        <VStack
          sx={{
            '.address-street': {
              fontSize: '3xl'
            },
            '.address-neighborhood': {
              fontSize: '2xl'
            }
          }}
          spacing={8}
          align="flex-start"
          w="full"
          flex={0.4}>
          <RealEstateAddress item={realEstate} />

          <BrokerContactCard broker={broker} />
        </VStack>

        <VStack flex={0.6}>
          <BrokerContactForm broker={broker} realEstate={realEstate} />
          <Text>
            Al proporcionar sus datos, acepta que su información personal sea recogida, retenida,
            utilizada y divulgada por nosotros de acuerdo con nuestra{' '}
            <Link href="/privacidad" color="blue.400" _hover={{ color: 'blue.500' }}>
              Política de privacidad
            </Link>
            .
          </Text>
        </VStack>
      </Stack>
    </CustomModal>
  )
}

export default ContactBrokerModal
