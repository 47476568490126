import ErrorHandler from '@/app/components/common/ErrorHandler'
import { BrokerDTO } from '@/core/broker/typings'
import { SendGridErrorResponse, sendContact } from '@/core/contact/api'
import { RealEstateDTO, RealEstateLightDTO } from '@/core/real-estate/typings'
import { RealestateDetailSendBrokerForm, gtmEvent } from '@/utils/gtm'
import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Text,
  VStack
} from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import { useCallback, useState } from 'react'
import * as yup from 'yup'

const BrokerContactForm = ({
  broker,
  realEstate
}: {
  broker: BrokerDTO
  realEstate: RealEstateDTO | RealEstateLightDTO
}) => {
  const [errors, setErrors] = useState<SendGridErrorResponse['response']['body']['errors'] | null>(
    null
  )
  const [contactSent, setContactSent] = useState(false)

  const validationSchema = yup.object().shape({
    name: yup.string().required('El campo nombre es requerido'),
    email: yup
      .string()
      .email('Correo electrónico no válido')
      .required('El campo correo electrónico es requerido'),
    phone: yup.string().matches(/^[0-9]{10,15}$/, 'El número de teléfono no es válido')
  })

  const handleOnSubmit = useCallback(
    async (values: FormikValues) => {
      const res = await sendContact(values, broker, realEstate)
      gtmEvent<RealestateDetailSendBrokerForm>('realestate_detail_send_broker_form', {
        broker_id: broker.id,
        message_length: values.message ? values.message.length : 0
      })

      if (typeof res === 'boolean') {
        setErrors(null)
        setContactSent(true)
      } else {
        setErrors(res)
      }
    },
    [broker, realEstate]
  )

  if (contactSent) {
    return (
      <VStack w="full" justifyContent="center" gap={4} py={8}>
        <Icon as={CheckCircleIcon} boxSize={16} color="green.400" />
        <Text align="center" fontSize="lg" fontWeight="bold">
          Hemos enviado tus datos al broker. Se pondrá en contacto contigo a la brevedad.
        </Text>
      </VStack>
    )
  }

  return (
    <VStack w="full">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
          email: '',
          role: 'Dueño de negocio',
          company: '',
          phone: '',
          message: ''
        }}
        onSubmit={handleOnSubmit}>
        {({ isValid, isSubmitting }) => (
          <Form>
            <Flex direction="column">
              <Box mt={4}>
                <Field name="name">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel htmlFor="name">Nombre</FormLabel>
                      <Input
                        variant="filled"
                        borderBottomColor="text.default"
                        borderBottomWidth={1}
                        _focus={{ borderBottomWidth: 2, borderColor: 'primary' }}
                        rounded="sm"
                        {...field}
                        id="name"
                        placeholder="Nombre"
                      />
                      <FormErrorMessage name="name" />
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box mt={4}>
                <Field name="email">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel htmlFor="email">Correo electrónico</FormLabel>
                      <Input
                        variant="filled"
                        borderBottomColor="text.default"
                        borderBottomWidth={1}
                        _focus={{ borderBottomWidth: 2, borderColor: 'primary' }}
                        rounded="sm"
                        {...field}
                        id="email"
                        placeholder="Correo electrónico"
                      />
                      <FormErrorMessage name="email" />
                    </FormControl>
                  )}
                </Field>
              </Box>

              <HStack w="full">
                <Box mt={4}>
                  <Field name="role">
                    {({ field }) => (
                      <FormControl>
                        <FormLabel htmlFor="role">Puesto</FormLabel>
                        <Select
                          {...field}
                          id="role"
                          rounded="sm"
                          borderBottomColor="text.default"
                          borderBottomWidth={1}
                          _focus={{ borderBottomWidth: 2, borderColor: 'primary' }}
                          bg="background.surface"
                          vaiant="filled">
                          <option value="Dueño de negocio">Dueño de negocio</option>
                          <option value="Tomador de decisiones para nuestro negocio">
                            Tomador de decisiones para nuestro negocio
                          </option>
                          <option value="Broker">Broker</option>
                          <option value="Inversionista">Inversionista</option>
                          <option value="Dueño de un negocio similar que necesito rentar o vender">
                            Dueño de un negocio similar que necesito rentar o vender
                          </option>
                          <option value="Otro">Otro</option>
                        </Select>
                        <FormErrorMessage name="role" />
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box mt={4}>
                  <Field name="company">
                    {({ field }) => (
                      <FormControl>
                        <FormLabel htmlFor="company">Compañía</FormLabel>
                        <Input
                          variant="filled"
                          borderBottomColor="text.default"
                          borderBottomWidth={1}
                          _focus={{ borderBottomWidth: 2, borderColor: 'primary' }}
                          rounded="sm"
                          {...field}
                          id="company"
                          placeholder="Compañía"
                        />
                        <FormErrorMessage name="company" />
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </HStack>

              <Box mt={4}>
                <Field name="phone">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel htmlFor="phone">Teléfono</FormLabel>
                      <Input
                        variant="filled"
                        borderBottomColor="text.default"
                        borderBottomWidth={1}
                        _focus={{ borderBottomWidth: 2, borderColor: 'primary' }}
                        rounded="sm"
                        {...field}
                        id="phone"
                        placeholder="Teléfono"
                      />
                      <FormErrorMessage name="phone" />
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box mt={4}>
                <Field name="message">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel htmlFor="message">Mensaje</FormLabel>
                      <Input
                        variant="filled"
                        borderBottomColor="text.default"
                        borderBottomWidth={1}
                        _focus={{ borderBottomWidth: 2, borderColor: 'primary' }}
                        rounded="sm"
                        {...field}
                        id="message"
                        placeholder="Mensaje"
                      />
                      <FormErrorMessage name="message" />
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Flex justifyContent="flex-end" mt={4} w="full">
                <Button
                  isDisabled={!isValid || isSubmitting}
                  bg="primary"
                  color="text.invert"
                  borderRadius="md"
                  type="submit">
                  Enviar
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
      <ErrorHandler errors={errors} />
    </VStack>
  )
}

export default BrokerContactForm

const FormErrorMessage = ({ name }: { name: string }) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <Text color="red.500" fontSize="sm" mt={1}>
        {msg}
      </Text>
    )}
  </ErrorMessage>
)